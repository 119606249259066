import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { CookieBot } from "react-cookiebot"
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf4MSQeAAAAAGb6wnQL17QryRBHmFn9MOZZsLot">
      {/* <CookieBot domainGroupId="eff4e514-217f-4fe8-bd10-0513f9bfb6a3" /> */}
      {element}
    </GoogleReCaptchaProvider>
  )
}
